<template>
  <div class="myPerformance">
    <!-- 绩效申诉 -->
    <van-nav-bar :title="$t('myPerformance.perTxt1')"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true" />
    <div class="content">
      <van-tabs v-model="activeName"
                color="#2B8DF0"
                title-active-color="#2B8DF0"
                @change="changeActive">
        <van-tab v-if="$t('myPerformance.perTxt2')"
                 :title="$t('myPerformance.perTxt2')"
                 name="myApp"></van-tab>
        <van-tab v-if="$t('myPerformance.perTxt3')"
                 :title="$t('myPerformance.perTxt3')"
                 name="xjApp"></van-tab>
      </van-tabs>
      <van-pull-refresh v-model="isLoadingAwait"
                        @refresh="onRefreshAwait">
        <van-list v-model="loadingAwait"
                  :finished="finishedAwait"
                  :finished-text="$t('module.noMore')"
                  @load="onLoadAwait">
          <div class="zb_group"
               v-for="(item, index) in assessList"
               :key="index"
               @click="goToList(item)">
            <div class="myTag"
                 v-if="activeName == 'myApp' && item.ssstatus == '已处理'">
              <van-tag mark
                       size="medium"
                       :color="item.isqr?'#7FBAF6':'#FFB366'"
                       type="primary">{{item.isqr?$t('myPerformance.perTxt4'):$t('myPerformance.perTxt5')}}</van-tag>
            </div>
            <van-cell class="ass_cell"
                      center
                      :title="item.khtimename"
                      :value="
                activeName == 'xjApp' ? item.sstype + '-' + item.empname : item.sstype
              "
                      is-link>

              <template #label>
                <div>{{item.planname}}</div>
                <div>{{item.fjreason}}</div>
              </template>
            </van-cell>
            <div v-if="activeName == 'myApp'"
                 class="ass_right">
              <div class="status_txt"
                   :class="
                  item.ssstatus == '同意'
                    ? 'agree'
                    : item.ssstatus == '否决'
                    ? 'un_agree'
                    :  item.ssstatus == '处理中'
                    ? 'assing'
                    : 'toBe_submit'
                ">
                <span>{{ item.ssstatus }}</span>
              </div>
              <!-- <img src="@/assets/img/360ty.png"
                   alt="" /> -->
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getXjSsList, getMySsList } from "@api/wxjx.js";
export default {
  data () {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {};
    return {
      userInfo,
      isLoadingAwait: false,
      loadingAwait: false,
      finishedAwait: false,
      assessList: [],
      activeName: "myApp",
    };
  },
  created () { },
  //beforeRouteLeave
  beforeRouteEnter (to, from, next) {
    let isMyapp = true;
    if (from.name == "jxAppealsDetail")
    {
      isMyapp = from.query.isMyapp;
    }
    next((vm) => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (isMyapp == true || isMyapp == "true")
      {
        vm.activeName = "myApp";
      } else
      {
        vm.activeName = "xjApp";
      }
    });
  },
  methods: {
    onClickLeft () {
      this.$router.push("/home");
    },
    // 获取“我的申诉”记录列表
    getMySsListData () {
      let _this = this;
      getMySsList({ empid: this.userInfo.empid }).then((res) => {
        if (res.data.length == 0)
        {
          _this.finishedAwait = true;
          // 无数据
        } else
        {
          _this.assessList = res.data;
          _this.loadingAwait = false;
          _this.finishedAwait = true;
        }
      });
    },
    // 获取“下级申诉”记录列表
    getXjSsListData () {
      let _this = this;
      getXjSsList({ empid: this.userInfo.empid }).then((res) => {
        if (res.data.length == 0)
        {
          _this.finishedAwait = true;
          // 无数据
        } else
        {
          _this.assessList = res.data;
          _this.loadingAwait = false;
          _this.finishedAwait = true;
        }
      });
    },
    onLoadAwait () {
      if (this.isLoadingAwait)
      {
        this.assessList = [];
        this.isLoadingAwait = false;
      }
      if (this.activeName == "myApp")
      {
        this.getMySsListData();
      } else
      {
        this.getXjSsListData();
      }
    },
    onRefreshAwait () {
      this.assessList = [];
      this.finishedAwait = false;
      this.loadingAwait = true;
      this.onLoadAwait();
      // Toast('刷新成功')
    },
    // tab切换事件
    changeActive (name, title) {
      this.onRefreshAwait();
    },
    // 去列表
    goToList (item) {
      console.log(item, "sadasdadasda");
      let title = "我的申诉";
      let isMyapp = true;
      let ssstatus = ''
      let isqr = false
      const mautoid = item.autoid;
      const empcode = item.empcode;
      if (this.activeName == "myApp")
      {
        title = this.$t('myPerformance.perTxt2')
        isMyapp = true;
        ssstatus = item.ssstatus
        isqr = item.isqr
      } else
      {
        title = this.$t('myPerformance.perTxt3')
        isMyapp = false;
      }
      // jxAppealsDetail
      this.$router.push({
        path: "/jxAppealsDetail",
        query: {
          title: title,
          isMyapp: isMyapp,
          mautoid: mautoid,
          empcode: empcode,
          sstype: item.sstype,
          ssstatus: ssstatus,
          isqr: isqr
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myPerformance {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    // overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 92px);
    /deep/.van-tabs {
      .van-tab {
        .van-tab__text {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
        }
      }
    }
    .van-pull-refresh {
      margin-top: 30px;
      overflow: auto;
      height: calc(100% - 120px);
      ::-webkit-scrollbar {
        display: none;
      }
    }
    .zb_group:first-of-type {
      margin-top: 0;
    }
    .zb_group {
      position: relative;
      display: flex;
      margin: 30px 0;
      .myTag {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        display: flex;
        .van-tag--mark {
          border-radius: 0 0 20px 0;
        }
      }
      .ass_cell {
        .van-cell__title {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          // line-height: 54px;
          color: #000000;
          .van-cell__label {
            font-size: 28px;
            font-weight: 500;
          }
        }
      }
      .ass_right {
        background-color: #fff;
        .status_txt {
          align-items: center;
          display: flex;
          justify-content: center;
          height: 164px;
          width: 100px;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          // background-image: url("~@/assets/img/360ty.png");
          span {
            width: 1em;
          }
        }
        .agree {
          background: url("~@/assets/img/360ty.png") no-repeat;
          background-size: 100% 100%;
        }
        .un_agree {
          background: url("~@/assets/img/360bty.png") no-repeat;
          background-size: 100% 100%;
        }
        .toBe_submit {
          background: url("~@/assets/img/360dtj.png") no-repeat;
          background-size: 100% 100%;
        }
        .assing {
          background: url("~@/assets/img/360sh.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    .van-button {
      width: 70%;
      border-radius: 12px;
      .van-button__text {
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: 500;
      }
    }
  }
}
</style>